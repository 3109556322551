import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';

export const EAdminPerspectiveView = {
	SETTINGS: {
		name: 'Settings',
		anchor: 'options',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./AdminOptionsView'),
		async canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_SERVER_OPTIONS);
		}
	} as ViewDescriptor,
	USERS: {
		name: 'Users',
		anchor: 'users',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./users/AdminUserView'),
		async canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_USERS);
		}
	} as ViewDescriptor,
	GROUPS: {
		name: 'Groups',
		anchor: 'groups',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./groups/AdminGroupView'),
		async canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_GROUPS);
		}
	} as ViewDescriptor,
	ROLES: {
		name: 'Roles',
		anchor: 'roles',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./AdminRoleView'),
		async canBeAccessed(context) {
			return (
				PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_ROLES) ||
				PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_GLOBAL_ROLES)
			);
		}
	} as ViewDescriptor,
	INSTANCE_COMPARISON: {
		name: 'Instance Comparison',
		anchor: 'instance-compare',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./instance-comparison/overview/InstanceComparisonView'),
		async canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES);
		}
	} as ViewDescriptor,
	EXTERNAL_CREDENTIALS: {
		name: 'External Accounts',
		anchor: 'external-accounts',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./ExternalCredentialsView'),
		async canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_EXTERNAL_CREDENTIALS);
		}
	} as ViewDescriptor,
	EXTERNAL_STORAGE_BACKENDS: {
		name: 'Storage Backends',
		anchor: 'external-storage-backends',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./ExternalStorageBackendsView'),
		async canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_EXTERNAL_CREDENTIALS);
		}
	} as ViewDescriptor,
	BACKUP: {
		name: 'Backup',
		anchor: 'backup',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./AdminBackupView'),
		async canBeAccessed(context) {
			return (
				PermissionUtils.hasGlobalPermission(context, EGlobalPermission.BACKUP) ||
				PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_PROJECTS)
			);
		}
	} as ViewDescriptor,
	NOTIFICATIONS: {
		name: 'Notifications',
		anchor: 'notifications',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./AdminNotificationsView'),
		async canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES);
		}
	} as ViewDescriptor,
	EVENT_ANNOUNCEMENT: {
		name: 'Event Announcement',
		anchor: 'event-announcement',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./event-announcement/EventAnnouncementView'),
		async canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES);
		}
	} as ViewDescriptor
} as const;
