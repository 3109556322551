// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/base/perspective/sidebar/left/NavTooltipTemplate.soy

/**
 * @fileoverview Templates in namespace ts.base.perspective.sidebar.NavTooltipTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$navTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $navTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $navTooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.perspective, opt_data.isActivePerspective, opt_data.viewsWithAnchors, opt_data.id, opt_data.project, opt_data.selectedView, opt_data.anchorLinkContentAfterProjectName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} perspective
 * @param {boolean} isActivePerspective
 * @param {?} viewsWithAnchors
 * @param {string} id
 * @param {?=} project
 * @param {?=} selectedView
 * @param {?=} anchorLinkContentAfterProjectName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $navTooltip$ = function($$areYouAnInternalCaller, $ijData, perspective, isActivePerspective, viewsWithAnchors, id, project, selectedView, anchorLinkContentAfterProjectName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip']) {
    return soy.$$stubsMap['ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip']({perspective: perspective, isActivePerspective: isActivePerspective, viewsWithAnchors: viewsWithAnchors, id: id, project: project, selectedView: selectedView, anchorLinkContentAfterProjectName: anchorLinkContentAfterProjectName}, $ijData);
  }
  soy.assertParamType(typeof isActivePerspective === 'boolean', 'isActivePerspective', isActivePerspective, '@param', 'boolean');
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  let $output = '';
  const shouldAppendProjectToUrl__soy44089 = soy.$$coerceToBoolean(project) && soy.$$coerceToBoolean(viewsWithAnchors[/** @type {?} */ (0)]) && soy.$$coerceToBoolean(viewsWithAnchors[/** @type {?} */ (0)].requiresProject);
  let mainLink__soy44091 = '' + perspective.simpleName + '.html' + (shouldAppendProjectToUrl__soy44089 ? '#/' + '' + project : '') + (shouldAppendProjectToUrl__soy44089 && soy.$$coerceToBoolean(viewsWithAnchors[/** @type {?} */ (0)].keepPathAndArgumentsOfCurrentViewForSubviews) ? '/' + '' + anchorLinkContentAfterProjectName : '');
  $output += '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(mainLink__soy44091)) + '" class="item" id="' + soy.$$escapeHtmlAttribute(id) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip ts/base/perspective/sidebar/left/NavTooltipTemplate.soy:28"' : '') + '><strong>' + soy.$$escapeHtml(perspective.displayName) + '</strong></a>' + (viewsWithAnchors.length > 0 ? '<div class="ui marginless divider"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip ts/base/perspective/sidebar/left/NavTooltipTemplate.soy:31"' : '') + '></div>' : '');
  const subView44133List = viewsWithAnchors;
  const subView44133ListLen = subView44133List.length;
  for (let subView44133Index = 0; subView44133Index < subView44133ListLen; subView44133Index++) {
    const subView44133Data = subView44133List[subView44133Index];
    $output += '<a class="item ' + (soy.$$coerceToBoolean(selectedView) && soy.$$equals(selectedView, subView44133Data.anchor) && isActivePerspective || !selectedView && isActivePerspective && subView44133Index == 0 ? ' active' : '') + ' tooltip-item-' + soy.$$escapeHtmlAttribute(subView44133Data.anchor) + ' perspective-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(perspective.simpleName + '.html#' + subView44133Data.anchor)) + '/' + (soy.$$coerceToBoolean(project) && soy.$$coerceToBoolean(subView44133Data.requiresProject) ? soy.$$escapeHtmlAttribute(project) : '') + (subView44133Data.keepPathAndArgumentsOfCurrentViewForSubviews ? '/' + soy.$$escapeHtmlAttribute(anchorLinkContentAfterProjectName) : '') + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip ts/base/perspective/sidebar/left/NavTooltipTemplate.soy:36"' : '') + '>' + soy.$$escapeHtml(subView44133Data.name) + '</a>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $navTooltip as navTooltip };
export { $navTooltip$ as navTooltip$ };
/**
 * @typedef {{
 *  perspective: ?,
 *  isActivePerspective: boolean,
 *  viewsWithAnchors: ?,
 *  id: string,
 *  project?: (?|undefined),
 *  selectedView?: (?|undefined),
 *  anchorLinkContentAfterProjectName?: (?|undefined),
 * }}
 */
$navTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $navTooltip.soyTemplateName = 'ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip';
}
