import type { ServiceCallError } from 'api/ServiceCallError';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { EventAnnouncementBanner } from 'ts/perspectives/admin/event-announcement/components/EventAnnouncementBanner';
import { AnalysisStateInfo } from 'ts/base/AnalysisStateInfo';
import type { Callback } from 'ts/base/Callback';
import { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { ServiceErrorComponent } from 'ts/base/components/ServiceErrorComponent';
import { useAsync } from 'ts/base/hooks/AsyncHook';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useActivePerspective } from 'ts/base/hooks/UseActivePerspective';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { TeamscaleViewBase } from 'ts/base/view/TeamscaleViewBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { ToastNotification } from 'ts/commons/ToastNotification';
import { tsdom } from 'ts/commons/tsdom';

/** Props for TeamscalePerspective. */
type TeamscaleViewWrapperProps = {
	view: TeamscaleViewBase;
	defaultBranchName: string | null;
	projectIds: string[];
};

/**
 * Wraps a TeamscalePerspectiveBase into React. It asynchronously loads the actual implementation of the perspective,
 * ensures Semantic UI is loaded, fetches the perspective context, instantiates the perspective and handles the
 * historyChanged lifecycle for the component.
 */
export function TeamscaleViewWrapper({ view, defaultBranchName, projectIds }: TeamscaleViewWrapperProps): JSX.Element {
	const context = usePerspectiveContext();
	const hash = useNavigationHash();
	const activePerspective = useActivePerspective();
	const mainContentLoaded = useRef<boolean>(false);
	const [error, setError] = useState<ServiceCallError | undefined>(undefined);
	const client = useRef(new TeamscaleServiceClient(error => handleError(error, mainContentLoaded.current, setError)));

	const mainContainerRef = useRef<HTMLDivElement | null>(null);
	const viewContentRef = useRef<HTMLDivElement | null>(null);

	const initAndPreloader = useCallback(() => {
		view.init(client.current, context, defaultBranchName, KeyboardShortcutRegistry.INSTANCE);
		return view.preloadContentAsync();
	}, [context, defaultBranchName, view]);
	const preloadResult = useAsync(initAndPreloader);

	useLayoutEffect(() => {
		if (preloadResult.status === 'success') {
			mainContentLoaded.current = true;
			const mainContainer = mainContainerRef.current!;
			view.renderInto(mainContainer, viewContentRef.current!, activePerspective, hash);
			// Focus the main container to enable keyboard scrolling
			mainContainer.focus();
			return () => {
				mainContainer.style.display = '';
				view.dispose();
				tsdom.removeAllChildren(mainContainer);
				mainContainer.style.display = 'none';
			};
		}
		return;
	}, [hash, context, activePerspective, view, preloadResult.status]);
	if (error != null) {
		return <ServiceErrorComponent error={error} resetErrorBoundary={() => window.location.reload()} />;
	}
	return (
		<TeamscaleViewContent
			ref={mainContainerRef}
			viewDescriptor={view.viewDescriptor!}
			defaultBranchName={defaultBranchName}
			projectIds={projectIds}
		>
			<div ref={viewContentRef} style={{ display: 'contents' }} />
		</TeamscaleViewContent>
	);
}

/** Props for TeamscaleViewContent. */
type TeamscaleViewContentProps = {
	projectIds: string[];
	defaultBranchName: string | null;
	viewDescriptor: ViewDescriptor;
	children: ReactNode;
};

/**
 * Wrapper around a Teamscale view that displays the analysis state and event announcements above the given children. It
 * also ensures that any open closure dialogs are removed from the DOm when the view is unmounted and sets the default
 * document title.
 */
export const TeamscaleViewContent = forwardRef<HTMLDivElement, TeamscaleViewContentProps>(function TeamscaleViewContent(
	{ projectIds, defaultBranchName, viewDescriptor, children }: TeamscaleViewContentProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const event = useUserInfo().eventAnnouncement;
	useRemoveClosureDialogsOnUnmount();
	useDocumentTitle(viewDescriptor);
	return (
		<div id="ts-main-container" tabIndex={-1} ref={ref}>
			<Segment basic id="main" style={{ padding: viewDescriptor.hasSmallPadding ? '13px' : undefined }}>
				<EventAnnouncementBanner event={event} />
				<AnalysisStateInfo
					viewDescriptor={viewDescriptor}
					defaultBranchName={defaultBranchName}
					projectIds={projectIds}
				/>
				{children}
			</Segment>
			{/* Right sidebar will be inserted here */}
		</div>
	);
});

function useDocumentTitle(viewDescriptor: ViewDescriptor) {
	const perspective = useActivePerspective();
	useEffect(() => {
		document.title = perspective.displayName;
		const viewTitle = viewDescriptor.name;
		if (viewTitle !== '') {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + viewTitle;
		}
		const project = NavigationHash.getProject();
		if (!StringUtils.isEmptyOrWhitespace(project)) {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + project;
		}
	}, [perspective.displayName, viewDescriptor.name]);
}

/**
 * Removes any potential opened dialogs by removing their div elements on unmount. For more details on the Dialog HTML
 * structure, please refer to the {@code Dialog} class in the file: lib/ui/dialog.d.ts
 */
function useRemoveClosureDialogsOnUnmount() {
	useEffect(
		() => () => {
			tsdom.removeNodes(document.querySelectorAll('.modal-dialog'));
			tsdom.removeNodes(document.querySelectorAll('.modal-dialog-bg'));
		},
		[]
	);
}

/** Responds to errors coming from communication to backend through service client. */
function handleError(error: ServiceCallError, mainContentLoaded: boolean, setError: Callback<ServiceCallError>): void {
	if (mainContentLoaded && error.statusCode === 0) {
		// Status 0 indicates that Teamscale server may be down for some unknown reason.
		// We do nothing here, so view content remain shown while
		// the view tells the user about this connection issue. Useful for auto-refreshing pages.
		ToastNotification.showUnreachableServerWarning();
		return;
	}
	setError(error);
}
