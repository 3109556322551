import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';

export const EProjectPerspectiveView = {
	PROJECTS: {
		name: 'Projects',
		anchor: 'project',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./ProjectView'),
		async canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_PROJECTS);
		}
	} as ViewDescriptor,
	ANALYSIS_PROFILES: {
		name: 'Analysis Profiles',
		anchor: 'analysis-profiles',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./AnalysisProfileView'),
		async canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_ANALYSIS_PROFILES);
		}
	} as ViewDescriptor,
	THRESHOLDS: {
		name: 'Thresholds',
		anchor: 'metric-threshold-configuration',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./MetricThresholdConfigurationView'),
		async canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(
				context,
				EConfigurationFeature.CONFIGURE_METRIC_THRESHOLD_CONFIGURATIONS
			);
		}
	} as ViewDescriptor,
	EXTERNAL_METRICS: {
		name: 'External Metrics',
		anchor: 'external-metric-descriptions',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./ExternalMetricsView'),
		async canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_EXTERNAL_METRICS_SCHEMA);
		}
	} as ViewDescriptor,
	EXTERNAL_FINDINGS: {
		name: 'External Findings',
		anchor: 'external-findings-descriptions',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./ExternalFindingsView'),
		async canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_EXTERNAL_FINDINGS_SCHEMA);
		}
	} as ViewDescriptor
} as const;
