import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ComponentType } from 'react';
import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { useDefaultBranch } from 'ts/base/components/branch-chooser/UseBranchInfos';
import { PerspectiveLoadingIndicator } from 'ts/base/components/PerspectiveLoadingIndicator';
import { useAsync } from 'ts/base/hooks/AsyncHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { PerspectiveContextProviders } from 'ts/base/ReactUtils';
import { absoluteLocationToArtificialPath } from 'ts/base/routing/PerspectiveHashHistory';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { TeamscaleViewContent, TeamscaleViewWrapper } from 'ts/base/TeamscaleViewWrapper';
import { TeamscaleViewBase } from 'ts/base/view/TeamscaleViewBase';
import type { ViewDescriptor, ViewFactoryModule } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { TimetravelUtils } from 'ts/commons/TimetravelUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import {
	EHashReloadBehavior,
	NavigationHashReloadBehaviorContext
} from './context/NavigationHashReloadBehaviorContext';

/** Props for TeamscaleViewContextWrapper. */
type TeamscaleViewContextWrapperProps = {
	viewDescriptor: ViewDescriptor;
	context: ExtendedPerspectiveContext;
	projectIds: string[];
};

/** It loads the actual implementation of the view, determines a valid commit if needed, loads the default branch(es). */
export function TeamscaleViewContextWrapper({
	viewDescriptor,
	context,
	projectIds
}: TeamscaleViewContextWrapperProps): JSX.Element | null {
	const hash = useNavigationHash();
	const client = useTeamscaleServiceClient();

	const viewLoaderResult = useAsync(
		useCallback(
			() => createViewComponent(client, context, hash, viewDescriptor),
			[client, context, hash, viewDescriptor]
		)
	);
	const providesTimetravel = viewDescriptor.timeTravel !== undefined;
	const shouldValidateCommit = providesTimetravel && hash.getProject() !== '';
	const defaultBranch = useDefaultBranch(projectIds, {
		useErrorBoundary: true,
		enabled: viewDescriptor.requiresProject
	});
	const commitToRedirectTo = useCommitToRedirectTo(hash, client, shouldValidateCommit, defaultBranch);
	if (commitToRedirectTo.commit && providesTimetravel && projectIds.length > 0) {
		hash.setCommit(commitToRedirectTo.commit);
		return (
			<Navigate replace to={absoluteLocationToArtificialPath(new URL(hash.toString(), window.location.href))} />
		);
	}

	const allRequiredDataIsLoaded =
		viewLoaderResult.status === 'success' &&
		(!viewDescriptor.requiresProject || defaultBranch.isSuccess) &&
		(!shouldValidateCommit || commitToRedirectTo.isLoaded);
	const ViewComponent = viewLoaderResult.value!;
	return (
		<SuspendingErrorBoundary>
			<PerspectiveContextProviders perspectiveContext={context}>
				{allRequiredDataIsLoaded ? (
					<ViewComponent
						projectIds={projectIds}
						defaultBranchName={
							viewDescriptor.requiresProject && projectIds.length > 0
								? asserts.assertString(defaultBranch.data, 'Default branch is not set!')
								: null
						}
					/>
				) : (
					<PerspectiveLoadingIndicator />
				)}
			</PerspectiveContextProviders>
		</SuspendingErrorBoundary>
	);
}

function useBranchValidation(
	project: string,
	client: TeamscaleServiceClient,
	shouldValidateCommitAndLoadDefaultBranch: boolean,
	...commits: Array<UnresolvedCommitDescriptor | null>
) {
	return useQuery(['branches-exist', project, ...commits], () => branchesExistOnServer(client, project, ...commits), {
		enabled: shouldValidateCommitAndLoadDefaultBranch,
		suspense: false,
		useErrorBoundary: true
	});
}

function getRedirectCommit(
	commitFromHash: UnresolvedCommitDescriptor | null,
	branchFromHashExists: boolean,
	defaultBranch: string,
	branchFromStorageExists: boolean,
	commitFromStorage: UnresolvedCommitDescriptor | null
): UnresolvedCommitDescriptor | null | undefined {
	const hasCommitInHash = commitFromHash !== null;
	if (
		commitFromHash != null &&
		((branchFromHashExists && !commitFromHash.isDefaultBranch()) ||
			commitFromHash.getBranchName() === defaultBranch)
	) {
		// If either a concrete existing branch is set or the default branch is set, which might not exist if there are
		// no commits in the project, no redirect is needed
		return undefined;
	} else if (branchFromHashExists) {
		// In the case that t=1234 branchFromHashExists will be true because the default branch is there
		// We want to keep the timestamp, but make the default branch explicit
		return UnresolvedCommitDescriptor.withExplicitDefaultBranch(commitFromHash, defaultBranch);
	} else if (!hasCommitInHash && branchFromStorageExists) {
		return commitFromStorage;
	} else {
		// Branch from commit in hash no longer exists (takes precedence over the commit from storage) or
		// no stored commit and an invalid commit in the hash -> load default branch on HEAD.
		return UnresolvedCommitDescriptor.latestOnBranch(defaultBranch);
	}
}

/**
 * Determines whether the view needs to navigate to a different commit. This is the case if no commit is set in the
 * navigation hash, which leads to a navigation to the last commit that was explicitly selected by the user. If a
 * deleted commit was selected we will navigate to the head revision of the default branch. The value from the hash
 * always overrides the explicitly selected commit.
 */
function useCommitToRedirectTo(
	hash: NavigationHash,
	client: TeamscaleServiceClient,
	shouldValidateCommitAndLoadDefaultBranch: boolean,
	defaultBranchResult: UseQueryResult<string | null>
): { isLoaded: boolean; commit?: UnresolvedCommitDescriptor | null } {
	const project = hash.getProject();
	const commitFromHash = hash.getCommit();
	const commitFromStorage = TimetravelUtils.getLastSelectedCommitFromStorage();
	const branchExistsResult = useBranchValidation(
		project,
		client,
		shouldValidateCommitAndLoadDefaultBranch,
		commitFromHash,
		commitFromStorage
	);
	if (!branchExistsResult.isSuccess || !defaultBranchResult.isSuccess) {
		return { isLoaded: false };
	}
	const [branchFromHashExists, branchFromStorageExists] = branchExistsResult.data;
	const defaultBranch = defaultBranchResult.data;

	if (!branchFromStorageExists) {
		TimetravelUtils.setCurrentCommit(null);
	}

	// For new tabs that were accessed e.g. from an external link or where a link was copy/pasted we want
	// to keep the commit from the URL.
	if (branchFromHashExists && commitFromHash != null && window.history.length <= 2) {
		TimetravelUtils.setCurrentCommit(commitFromHash);
	}
	return {
		isLoaded: true,
		commit: getRedirectCommit(
			commitFromHash,
			branchFromHashExists!,
			defaultBranch!,
			branchFromStorageExists!,
			commitFromStorage
		)
	};
}

type TeamscaleViewProps = { projectIds: string[]; defaultBranchName: string | null };

/** Factory method that returns the view component to be rendered based on the view descriptor and navigation hash. */
async function createViewComponent(
	client: TeamscaleServiceClient,
	context: ExtendedPerspectiveContext,
	hash: NavigationHash,
	viewDescriptor: ViewDescriptor
): Promise<ComponentType<TeamscaleViewProps>> {
	if ('viewFactory' in viewDescriptor) {
		return createViewComponentViaFactory(viewDescriptor, await viewDescriptor.viewFactory(), hash, client, context);
	} else if ('viewCreator' in viewDescriptor) {
		const viewModule = await viewDescriptor.viewCreator();
		return createViewComponentFromTeamscaleViewBase(viewDescriptor, new viewModule.default());
	} else {
		const { default: View } = await viewDescriptor.view();
		return createViewComponentFromReactComponent(viewDescriptor, <View />);
	}
}

async function createViewComponentViaFactory(
	viewDescriptor: ViewDescriptor,
	viewFactoryModule: ViewFactoryModule,
	hash: NavigationHash,
	client: TeamscaleServiceClient,
	context: ExtendedPerspectiveContext
) {
	const viewCreatorInstance = new viewFactoryModule.default();
	const viewInstanceOrComponent = await viewCreatorInstance.createView(hash, client, context);
	if (viewInstanceOrComponent instanceof TeamscaleViewBase) {
		return createViewComponentFromTeamscaleViewBase(viewDescriptor, viewInstanceOrComponent);
	} else {
		return createViewComponentFromReactComponent(viewDescriptor, viewInstanceOrComponent);
	}
}

async function createViewComponentFromTeamscaleViewBase(
	viewDescriptor: ViewDescriptor,
	viewInstance: TeamscaleViewBase
) {
	viewInstance.setViewDescriptor(viewDescriptor);
	return function ViewComponent(props: TeamscaleViewProps) {
		return <TeamscaleViewWrapper view={viewInstance} {...props} />;
	};
}

function createViewComponentFromReactComponent(viewDescriptor: ViewDescriptor, view: JSX.Element) {
	return function ViewComponent(props: TeamscaleViewProps) {
		return (
			<NavigationHashReloadBehaviorContext.Provider value={EHashReloadBehavior.RELOAD_ALL}>
				<TeamscaleViewContent viewDescriptor={viewDescriptor} {...props}>
					{view}
				</TeamscaleViewContent>
			</NavigationHashReloadBehaviorContext.Provider>
		);
	};
}

/**
 * Determines if the branches from the given commits exist for the current project.
 *
 * @param commits A list of commits containing the branch names to test.
 * @returns Whether the branches exist for the current project.
 */
async function branchesExistOnServer(
	client: TeamscaleServiceClient,
	project: string,
	...commits: Array<UnresolvedCommitDescriptor | null>
): Promise<boolean[]> {
	const branchesToLookup = commits
		.map(commit => {
			if (commit !== null) {
				return commit.getBranchName();
			}
			return null;
		})
		.filter(branch => branch !== null);
	const existingBranchesInfo = await client.getFilteredBranchesInfoForProject(project, branchesToLookup);
	return commits.map(commit => {
		if (commit !== null) {
			const branchName = commit.getBranchName();
			if (branchName === null) {
				// Default branch
				return true;
			}
			return (
				existingBranchesInfo.liveBranches.includes(branchName) ||
				existingBranchesInfo.deletedBranches.includes(branchName) ||
				existingBranchesInfo.anonymousBranches.includes(branchName) ||
				existingBranchesInfo.virtualBranches.includes(branchName)
			);
		}
		return false;
	});
}
