import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';

/** Describes all views in the System perspective */
export const ESystemPerspectiveView = {
	EXECUTION_STATUS: {
		name: 'Execution Status',
		anchor: 'execution',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./SystemExecutionView')
	} as ViewDescriptor,
	WORKER_LOG: {
		name: 'Worker Log',
		anchor: 'worker-log',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./worker_log/SystemWorkerLogView')
	} as ViewDescriptor,
	SERVICE_LOG: {
		name: 'Service Log',
		anchor: 'service-log',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./SystemServiceLogView')
	} as ViewDescriptor,
	PARSE_LOG: {
		name: 'Parse Log',
		anchor: 'parse-log',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./SystemParseLogView')
	} as ViewDescriptor,
	EVENT_LOG: {
		name: 'Event Log',
		anchor: 'event-log',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./EventLogView')
	} as ViewDescriptor,
	INTERACTION_LOG: {
		name: 'Interaction Log',
		anchor: 'interaction-log',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./InteractionLogView')
	} as ViewDescriptor,
	JAVASCRIPT_LOG: {
		name: 'JavaScript Error Log',
		anchor: 'js-error-log',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./JavaScriptErrorPerspective')
	} as ViewDescriptor,
	SYSTEM_INFO: {
		name: 'System Information',
		anchor: 'system-info',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./SystemInfoView')
	} as ViewDescriptor,
	LICENSE_INFO: {
		name: 'License Information',
		anchor: 'license',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./SystemLicenseView'),
		async canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES);
		}
	} as ViewDescriptor
} as const;
