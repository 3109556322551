import { Image } from 'semantic-ui-react';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import LogoWithText from './teamscale-logo-white-text.svg';
import LogoOnly from './teamscale-logo-without-text.svg';

/** Props for TeamscaleLogo. */
type TeamscaleLogoProps = { iconOnlySidebar: boolean };

/** The Teamscale logo shown at the top of the sidebar. */
export function TeamscaleLogo({ iconOnlySidebar }: TeamscaleLogoProps): JSX.Element {
	const instanceName = useTeamscaleInfo().instanceName;

	return (
		<div className="item logo">
			<Image
				width={iconOnlySidebar ? 30 : 125}
				height={iconOnlySidebar ? 30 : 27}
				size="small"
				alt="Teamscale Logo"
				className="teamscale-logo"
				src={iconOnlySidebar ? LogoOnly : LogoWithText}
			/>
			{instanceName ? (
				<div id="instance-name" title={instanceName}>
					{instanceName}
				</div>
			) : null}
		</div>
	);
}
