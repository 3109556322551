import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import { useAccessibleSidebarViews } from 'ts/base/hooks/UseAccessibleViews';
import { useActivePerspective } from 'ts/base/hooks/UseActivePerspective';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { SidebarTooltip } from 'ts/base/perspective/sidebar/left/SidebarTooltip';
import { SubViewMenu } from 'ts/base/perspective/sidebar/left/SubViewMenu';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for LeftSidebarEntry. */
type LeftSidebarEntryProps = {
	perspective: ETeamscalePerspective;
	iconOnlySidebar: boolean;
	perspectiveDescriptor: PerspectiveViewDescriptorBase;
};

/** Represents a single perspective entry in the left sidebar. Takes care of attaching the floating submenus. */
export function LeftSidebarEntry({
	perspective,
	iconOnlySidebar,
	perspectiveDescriptor
}: LeftSidebarEntryProps): JSX.Element {
	const activePerspective = useActivePerspective();
	const isActive = perspective.name === activePerspective.name;
	const projectInfo = useProjectIfExists();

	const accessibleSubviews = useAccessibleSidebarViews(perspectiveDescriptor, { suspense: false });

	// Links to project-specific perspectives or to the Dashboard perspective should keep the currently selected project (if available)
	// We use the first subview here (if already loaded) because the perspective link will lead to the first subview
	const isProjectSpecific = accessibleSubviews[0]?.requiresProject ?? false;
	let href = perspective.page;
	if (isProjectSpecific && projectInfo !== null) {
		href += `#/${projectInfo.primaryId}`;
	}

	const linkRef = useFloatingPerspectiveViewPopup(perspective, iconOnlySidebar, accessibleSubviews);
	return (
		<>
			<TeamscaleLink
				id={'link-' + perspective.simpleName}
				className={'perspective-link item ' + (isActive ? ' active' : '')}
				to={href}
				data-index={perspective.ordinal}
				ref={linkRef}
			>
				<div className="item-wrapper">
					{perspective.iconClass ? <Icon name={perspective.iconClass as SemanticICONS} /> : null}
					<span className="item__text">{perspective.displayName}</span>
				</div>
			</TeamscaleLink>
			{isActive ? <SubViewMenu views={accessibleSubviews} /> : null}
		</>
	);
}

// We cache the height of a link in order to not do it again for every single sidebar entry, as this will
// lead to multiple forced redraws and cost us >50ms
let cachedLinkHeight: number | undefined = undefined;

/** Attaches the floating perspective menu to the perspective link that is shown on hover. */
function useFloatingPerspectiveViewPopup(
	perspective: ETeamscalePerspective,
	iconOnlySidebar: boolean,
	accessibleSubviews: ViewDescriptor[] | null
): MutableRefObject<HTMLAnchorElement | null> {
	const activePerspective = useActivePerspective();
	const viewName = useNavigationHash().getViewName();
	const linkRef = useRef<HTMLAnchorElement | null>(null);

	useEffect(() => {
		if (accessibleSubviews === null) {
			// Available submenus are still loading
			return;
		}
		const perspectiveLink = linkRef.current!;
		cachedLinkHeight = cachedLinkHeight ?? perspectiveLink.offsetHeight;
		const tooltip = new SidebarTooltip(
			perspectiveLink,
			activePerspective,
			perspective,
			iconOnlySidebar,
			cachedLinkHeight,
			accessibleSubviews,
			viewName
		);
		return () => {
			tooltip.detach();
			// @ts-ignore Method is protected, but we need to call it will crash otherwise when the
			// MOUSEOUT event handler fires after the tooltip got destroyed
			tooltip.clearHideTimer();
			tooltip.dispose();
		};
	}, [accessibleSubviews, activePerspective, iconOnlySidebar, perspective, viewName]);
	return linkRef;
}
