import type { ProfilerInfo } from 'api/ServiceClientImplementation';
import { ServiceClientImplementation } from 'api/ServiceClientImplementation';
import clsx from 'clsx';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Icon, Message, Segment, Table } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import { useLocalStorage } from 'ts/commons/hooks/UseLocalStorage';
import styles from './DebuggingPanel.module.less';

/** The possible display states of the UI Debugger. */
enum DebuggerDisplayState {
	LINKS,
	PROFILER,
	MINIFIED
}

/** Panel for controlling the integrated UI debugger and profiler. */
export function DebuggingPanel(): JSX.Element | null {
	const [enabled, setEnabled] = useUiDebuggerEnablement();
	const [displayState, setDisplayState] = useState(DebuggerDisplayState.MINIFIED);
	if (!enabled) {
		return null;
	}
	return (
		<Segment
			raised
			className={clsx(styles.uiDebuggerSegment, {
				[styles.hoverDim!]: displayState === DebuggerDisplayState.MINIFIED
			})}
		>
			<DebuggerTabHeader
				displayState={displayState}
				onDisplayStateChange={setDisplayState}
				onClose={() => setEnabled(false)}
			/>
			{displayState === DebuggerDisplayState.LINKS ? <LinksPanel /> : null}
			{displayState === DebuggerDisplayState.PROFILER ? <ProfilerPanel /> : null}
		</Segment>
	);
}

/**
 * Returns the current enablement state of the ui debugger and allows to programmatically change it. By default the
 * debugger is disabled. The state is persisted across page reloads in the local storage and can be toggled with
 * Ctrl+Alt+D/B keyboard shortcut.
 */
function useUiDebuggerEnablement(): [boolean, Dispatch<SetStateAction<boolean>>] {
	const [enabled, setEnabled] = useLocalStorage('ui-debugger-enabled', false);
	const handleUserKeyPress = useCallback(
		event => {
			// We react on both D and B, as some OS/Browser combinations catch the Ctrl+Alt+D/B
			if (event.altKey && event.ctrlKey && (event.keyCode === 68 || event.keyCode === 66)) {
				setEnabled(enabled => !enabled);
			}
		},
		[setEnabled]
	);
	useEffect(() => {
		window.addEventListener('keydown', handleUserKeyPress);
		return () => window.removeEventListener('keydown', handleUserKeyPress);
	}, [handleUserKeyPress]);
	return [enabled, setEnabled];
}

/** Props for DebuggerTabHeader. */
type DebuggerTabHeaderProps = {
	displayState: DebuggerDisplayState;
	onDisplayStateChange: Callback<DebuggerDisplayState>;
	onClose: () => void;
};

/** Shows the header of the UI debugger. */
function DebuggerTabHeader({ displayState, onDisplayStateChange, onClose }: DebuggerTabHeaderProps): JSX.Element {
	return (
		<div className={styles.tabHeaders}>
			<strong>UI Debugger enabled</strong>
			<span className="pull-right">
				<button
					className="ts-dropdown"
					id="debugger-links"
					title="Useful links"
					onClick={() => onDisplayStateChange(DebuggerDisplayState.LINKS)}
				>
					<Icon name="linkify" />
				</button>
				<button
					className="ts-dropdown"
					id="debugger-profiler"
					title="Display profiling information"
					onClick={() => onDisplayStateChange(DebuggerDisplayState.PROFILER)}
				>
					<Icon name="wait" />
				</button>
				{displayState === DebuggerDisplayState.MINIFIED ? null : (
					<button
						className="ts-dropdown"
						id="debugger-collapse"
						title="Minify debugger"
						onClick={() => onDisplayStateChange(DebuggerDisplayState.MINIFIED)}
					>
						<Icon name="toggle down" />
					</button>
				)}
				<button className="ts-dropdown" id="debugger-close" title="Disable debugger" onClick={onClose}>
					<Icon name="remove" />
				</button>
			</span>
		</div>
	);
}

/** Shows the links panel of the debugger. */
function LinksPanel(): JSX.Element {
	return (
		<div>
			<div>
				<strong>Performance</strong>
			</div>
			<ul>
				<li>
					<a href="api/debug/performance/trigger/aggregate.csv">Aggregated Trigger Data</a>
				</li>
				<li>
					<a href="api/debug/performance/trigger/details.csv">Detailed Trigger Data (if activated)</a>
				</li>
				<li>
					<a href="api/debug/performance/store-details.csv">Detailed Storage Access Data (if activated)</a>
				</li>
				<li>
					<a href="api/debug/performance/state-changes.csv">Analysis State Changes</a>
				</li>
			</ul>
			<div>
				<strong>Debugging</strong>
			</div>
			<ul>
				<li>
					<a target="_blank" href="api/debug/thread-dump">
						Thread Dump
					</a>
				</li>
				<li>
					<a target="_blank" href="api/debug/heap-dump">
						Heap Dump
					</a>
				</li>
			</ul>
			<div>
				<strong>Administration</strong>
			</div>
			<ul>
				<li>
					<a target="_blank" href="api/scheduler">
						Scheduler control
					</a>
				</li>
				<li>
					<a target="_blank" href="api/shutdown">
						Shutdown Teamscale
					</a>
				</li>
				<li>
					<a target="_blank" href="api/database-compaction">
						Database compaction
					</a>
				</li>
				<li>
					<a target="_blank" href="indexdoc.html">
						Index documentation
					</a>
				</li>
			</ul>
		</div>
	);
}

/**
 * Shows the profiler panel of the debugger that shows detailed service call metrics for the services that are called
 * while the panel is shown.
 */
function ProfilerPanel(): JSX.Element {
	const [profilingInfos, setProfilingInfos] = useState<ProfilerInfo[]>([]);
	useEffect(() => {
		ServiceClientImplementation.setProfilerHook(profilerInfo =>
			setProfilingInfos(infos => [...infos, profilerInfo])
		);
		return () => ServiceClientImplementation.setProfilerHook(null);
	}, []);
	if (profilingInfos.length === 0) {
		return <Message>No service calls executed so far</Message>;
	}
	return (
		<Table compact basic size="small" padded="very">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>Called URL</Table.HeaderCell>
					<Table.HeaderCell>Start time</Table.HeaderCell>
					<Table.HeaderCell>Overall millis</Table.HeaderCell>
					<Table.HeaderCell>Storage millis</Table.HeaderCell>
					<Table.HeaderCell>Storage calls</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{profilingInfos.map(profilingInfo => (
					<Table.Row key={profilingInfo.startTimeMillis + profilingInfo.url}>
						<Table.Cell>
							<a target="_blank" rel="noreferrer" href={profilingInfo.url}>
								{profilingInfo.url}
							</a>
						</Table.Cell>
						<Table.Cell>{profilingInfo.startTimeMillis}</Table.Cell>
						<Table.Cell>{profilingInfo.overallTimeMillis}</Table.Cell>
						<Table.Cell>{profilingInfo.storageTimeMillis}</Table.Cell>
						<Table.Cell>{profilingInfo.storageCalls}</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	);
}
