import 'date-input-polyfill';
import ReactDOM from 'react-dom';
import 'requestidlecallback-polyfill';
import { Component } from 'ts-closure-library/lib/ui/component';
import { App } from 'ts/App';
import { navigateTo } from 'ts/base/routing/NavigateTo';
import { UIUtils } from 'ts/commons/UIUtils';
import Redirects from 'ts/Redirects';
import './../styles/main.less';

/**
 * The key for the local storage containing a redirect URL. This mechanism is used to preserve the original redirect URL
 * even when going through a separate SSO page.
 */
export const REDIRECT_LOCATION_STORAGE_KEY = 'teamscale-redirect';

/**
 * Redirects to target URL before login with SSO. Target URL is stored temporarily in the browser local storage until
 * the SSO mechanism redirects us back to Teamscale.
 */
function redirectToTargetURLBeforeSSOLogin(): boolean {
	// Handle possible redirection right at the beginning
	const localStorage = UIUtils.getLocalStorage();
	const redirectTarget = localStorage.get(REDIRECT_LOCATION_STORAGE_KEY);
	if (redirectTarget != null) {
		localStorage.remove(REDIRECT_LOCATION_STORAGE_KEY);
		navigateTo(redirectTarget, true);
	}
	return Boolean(redirectTarget);
}

/** Redirects if the URL is rewritten for backwards compatibility. */
function redirectForBackwardsCompatibleURLs(): boolean {
	const maybeRedirect = Redirects.createRedirectOrNull(window.location);
	if (maybeRedirect !== null) {
		navigateTo(maybeRedirect, true);
	}
	return Boolean(maybeRedirect);
}

/** Main entry point into Teamscale which parses the current path, loads and shows the corresponding perspective. */
function showPerspective(): void {
	// First we need to redirect to the URL stored before login because it may have to be rewritten afterwards as well.
	if (redirectToTargetURLBeforeSSOLogin()) {
		// The current page may render too quickly before the redirection process starts.
		return;
	}
	redirectForBackwardsCompatibleURLs();

	// Make sure the Closure library knows about left to right order, as computing this is very expensive and occurs
	// frequently.
	Component.setDefaultRightToLeft(false);

	const appRoot = document.getElementById('app');
	ReactDOM.render(<App />, appRoot);
}

document.addEventListener('DOMContentLoaded', showPerspective);
