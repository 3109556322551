import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';

/** Warns the user about usage of an in-memory database. */
export function useInMemoryWarning(): JSX.Element | null {
	const teamscaleInfo = useTeamscaleInfo();
	if (!teamscaleInfo.showInMemoryWarning) {
		return null;
	}
	return (
		<GlobalWarning
			message="Non-persistent DB configured."
			tooltip={
				'You are currently using an in-memory database. ' +
				'Configuration settings and analysis results will not ' +
				'be persisted if Teamscale is shut down.'
			}
			severity="warning"
		/>
	);
}
