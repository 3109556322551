import { useQuery } from '@tanstack/react-query';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';

/**
 * Loads the project IDs for which data is shown in the view. By default, this is the project from the navigation hash,
 * but in dashboards that reference multiple projects it will be all projects that are referenced from the currently
 * selected dashboard regardless of the project selector selection. This influences the branches that are shown in the
 * perspective settings bar.
 */
export function useProjectIds(
	hash: NavigationHash,
	viewDescriptor: ViewDescriptor
): {
	isLoaded: boolean;
	isLoadedAfterMount: boolean;
	requestedProjectIds: string[];
	existingProjectIds: string[];
} {
	const client = useTeamscaleServiceClient();
	const context = usePerspectiveContext();
	const projects = context.getAllProjects();
	const result = useQuery(
		['projects', hash, viewDescriptor, projects],
		() => loadProjects(hash, viewDescriptor, client, projects),
		{
			suspense: false,
			useErrorBoundary: true,
			refetchOnMount: 'always'
		}
	);
	if (result.isSuccess) {
		return {
			isLoaded: true,
			isLoadedAfterMount: result.isFetchedAfterMount,
			requestedProjectIds: result.data,
			existingProjectIds: result.data.filter(project => context.projectExists(project))
		};
	} else {
		return { isLoaded: false, isLoadedAfterMount: false, requestedProjectIds: [], existingProjectIds: [] };
	}
}

/**
 * Determines the projects that are shown in the current view. This only returns meaningful data for views that have
 * ViewDescriptor#requireProjects. Typically, this is the currently selected project. For dashboards, it is all projects
 * that are referenced in the currently shown dashboard. For other views that allow to select all projects it is all
 * projects if "All projects" is selected (search perspective).
 */
export function loadProjects(
	hash: NavigationHash,
	viewDescriptor: ViewDescriptor,
	client: TeamscaleServiceClient,
	allProjects: string[]
): Promise<string[]> {
	if (viewDescriptor.getProjects) {
		return viewDescriptor.getProjects(client, hash);
	} else if (hash.getProject() === '') {
		return Promise.resolve(allProjects);
	} else {
		return Promise.resolve([hash.getProject()]);
	}
}
