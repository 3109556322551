import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EHashReloadBehavior } from 'ts/base/context/NavigationHashReloadBehaviorContext';
import { DebuggingPanel } from 'ts/base/perspective/debugger/DebuggingPanel';
import { BaseProviders } from 'ts/base/ReactUtils';
import { PerspectiveHashRouter as Router } from 'ts/base/routing/PerspectiveHashRouter';
import { TeamscalePerspectiveRoot } from 'ts/base/TeamscalePerspectiveRoot';
import { DECLARATION_OF_ACCESSIBILITY_LINK_WITHOUT_HTML } from 'ts/commons/accessibility/DeclarationOfAccessibility';
import { StringUtils } from 'ts/commons/StringUtils';
import { TeamscaleLoginPage } from 'ts/perspectives/login/TeamscaleLoginPage';
import { NotFound } from 'ts/NotFoundPage';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import '../tailwind.less';
import { ErrorReportingComponent } from './base/components/ErrorReportingComponent';
import { PERSPECTIVES } from './Perspectives';

const DeclarationOfAccessibilityPage = React.lazy(() =>
	import('ts/perspectives/accessibility/DeclarationOfAccessibilityPage')
);

function getRouterPath(perspective: ETeamscalePerspective) {
	return StringUtils.stripSuffix(perspective.page, '.html') + '/*';
}

/** Top level router that instantiates the perspective named in the current browser url. */
export function App(): JSX.Element {
	return (
		<BaseProviders hashReloadBehavior={EHashReloadBehavior.RELOAD_IGNORE_SILENTLY_APPLIED_CHANGES}>
			<ErrorReportingComponent>
				<Router>
					<Routes>
						<Route path="/" element={<Navigate to="/dashboard" replace />} />
						<Route path="index" element={<Navigate to="/dashboard" replace />} />
						{PERSPECTIVES.map(perspectiveDescriptor => (
							<Route
								key={perspectiveDescriptor.perspective.name}
								path={getRouterPath(perspectiveDescriptor.perspective)}
								element={
									<TeamscalePerspectiveRoot
										key={perspectiveDescriptor.perspective.name}
										perspectiveDescriptor={perspectiveDescriptor}
									/>
								}
							/>
						))}
						<Route
							path={DECLARATION_OF_ACCESSIBILITY_LINK_WITHOUT_HTML}
							element={<DeclarationOfAccessibilityPage />}
						/>
						<Route path="login/*" element={<TeamscaleLoginPage />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Router>
				<DebuggingPanel />
			</ErrorReportingComponent>
		</BaseProviders>
	);
}
