import { isSameDay } from 'date-fns';
import { format, toDate } from 'date-fns-tz';
import { useState } from 'react';
import { Button, Grid, Header, Icon, Message } from 'semantic-ui-react';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { ToastNotification } from 'ts/commons/ToastNotification';
import styles from 'ts/perspectives/admin/event-announcement/components/EventPromotion.module.css';
import type { EventAnnouncement } from 'typedefs/EventAnnouncement';

type EventAnnouncementBannerProps = {
	bannerId?: string;
	event: EventAnnouncement | undefined;
};

/** A banner announcing an event to the user. */
export function EventAnnouncementBanner({
	bannerId = 'event-announcement-message',
	event
}: EventAnnouncementBannerProps): JSX.Element | null {
	const client = useTeamscaleServiceClient();
	const [hidden, setHidden] = useState(false);
	if (event === undefined || hidden) {
		return null;
	}
	const startTime = toDate(event.startIsoLocalDateTime, { timeZone: event.timezone });
	const endTime = toDate(event.endIsoLocalDateTime, { timeZone: event.timezone });
	const sameDay = isSameDay(startTime, endTime);
	return (
		<Message info id={bannerId}>
			<Message.Content>
				<Grid>
					<Grid.Column width="12">
						<Header as="h3">{event.headline}</Header>
						{sameDay ? format(startTime, 'MMM d R, H:mm - ') + format(endTime, 'H:mm') : null}
						{!sameDay && format(startTime, 'MMM d R, H:mm - ') + format(endTime, 'MMM d R, H:mm')}{' '}
						{format(startTime, 'z')} - {event.description}
					</Grid.Column>
					<Grid.Column width="2" verticalAlign="middle">
						<a href={event.learnMoreUrl}>
							<Icon name="info circle" />
							<strong>Learn more</strong>
						</a>
					</Grid.Column>
					<Grid.Column width="2" verticalAlign="middle">
						<Button
							id={event.eventId === 'preview' ? undefined : 'hide-forever'}
							className={styles.hideForever}
							icon
							onClick={() => {
								if (event.eventId === 'preview') {
									return;
								}
								client
									.hideEventAnnouncement(event.eventId)
									.then(() => setHidden(true))
									.catch(reason => ToastNotification.error('Could not reach server. ' + reason));
							}}
						>
							<Icon name="close" /> Hide forever
						</Button>
					</Grid.Column>
				</Grid>
			</Message.Content>
		</Message>
	);
}
