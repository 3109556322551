import { ProjectResolver } from 'ts/base/ProjectResolver';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { EDashboardPerspectiveView } from 'ts/perspectives/dashboard/EDashboardPerspectiveView';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the dashboard perspective. */
export class DashboardPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.DASHBOARD, EDashboardPerspectiveView);
	}

	public override getProject(
		perspectiveContext: ExtendedPerspectiveContext,
		hash: NavigationHash,
		viewDescriptor: ViewDescriptor
	): string | null {
		const projectResolver = new ProjectResolver(perspectiveContext);
		return projectResolver.getProjectForDashboardPerspective(hash);
	}
}
