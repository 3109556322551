import { useActivePerspective } from 'ts/base/hooks/UseActivePerspective';
import { useCustomCss } from 'ts/base/hooks/UseCustomCss';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { LeftSidebar } from 'ts/base/perspective/sidebar/left/LeftSidebar';
import { PerspectiveContextProviders } from 'ts/base/ReactUtils';
import { usePerspectiveContextQuery } from 'ts/base/services/PerspectiveContext';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { TeamscalePerspectiveProjectRedirector } from 'ts/base/TeamscalePerspectiveProjectRedirector';
import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { ERepositoryPerspectiveView } from 'ts/perspectives/repository/ERepositoryPerspectiveView';
import { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for TeamscalePerspectiveRoot. */
type TeamscalePerspectiveRootProps = {
	perspectiveDescriptor: PerspectiveViewDescriptorBase;
};

/**
 * Handles drawing the skeleton of a perspective and loading the requested view.
 *
 * Navigation within one perspective is performed via the history token. The history token is the URL part after the '#'
 * (also known as anchor or hash). It is called so, because it is a part of the URL and the browser keeps the value of
 * this token in the browser history, which allows the user to navigate via forward/backward buttons. The idea is to
 * encode the local (visual) state of the perspective in this token. This can also be used for "normal" navigation. For
 * example, when a user clicks a link to "#target", this changes the history token to "target" and the perspective
 * reacts to this change.
 */
export function TeamscalePerspectiveRoot({ perspectiveDescriptor }: TeamscalePerspectiveRootProps): JSX.Element | null {
	const { data: perspectiveContext } = usePerspectiveContextQuery();
	useRepositoryRedirection(perspectiveContext);
	useCustomCss(perspectiveContext?.teamscaleInfo.customCss);
	return (
		<div className="ts-root-main" key="ts-root-main">
			<PerspectiveContextProviders key="perspectiveContext" perspectiveContext={perspectiveContext!}>
				<LeftSidebar key="sidebar" />
				<SuspendingErrorBoundary>
					<TeamscalePerspectiveProjectRedirector perspectiveDescriptor={perspectiveDescriptor} />
				</SuspendingErrorBoundary>
			</PerspectiveContextProviders>
		</div>
	);
}

/**
 * Handles the case of an empty Teamscale.io profile. Users without any projects only see the setup wizard and user
 * profile until they have created their first project. Additionally, the GitHub OAuth might have attached some state
 * when the user came back from enabling permissions for the GitHub app so we want to redirect them to the exact same
 * spot they left off.
 */
function useRepositoryRedirection(perspectiveContext: undefined | ExtendedPerspectiveContext) {
	const hash = useNavigationHash();
	const activePerspective = useActivePerspective();

	/**
	 * Determines if the perspective needs to redirect to the repositories perspective. This should be done when a
	 * provisioned user first visits Teamscale and has no projects configured yet.
	 */
	function needsToRedirectToRepositoryPerspective(
		hash: NavigationHash,
		perspectiveContext: ExtendedPerspectiveContext
	): boolean {
		const isInUserView = activePerspective.name === ETeamscalePerspective.USER.name;
		const isAlreadyInSetupView =
			activePerspective.name === ETeamscalePerspective.REPOSITORIES.name &&
			hash.getViewName() === ERepositoryPerspectiveView.SETUP.anchor;
		const hasStateFromGitHub = getUrlParameter('state') != null;
		return (
			(activePerspective.name === ETeamscalePerspective.REPOSITORIES.name && hasStateFromGitHub) ||
			(!isAlreadyInSetupView &&
				!isInUserView &&
				perspectiveContext.getAllProjects().length === 0 &&
				PermissionUtils.mayAccessFeature(perspectiveContext, EConfigurationFeature.CONFIGURE_REPOSITORIES))
		);
	}

	if (perspectiveContext != null && needsToRedirectToRepositoryPerspective(hash, perspectiveContext)) {
		navigateToRepositoriesPerspective();
	}
}

/** Navigates to the repositories perspective and the add new repository subview. */
function navigateToRepositoriesPerspective(): void {
	let navigationHash = new NavigationHash();
	const stateFromGitHub = getUrlParameter('state');
	if (stateFromGitHub != null) {
		navigationHash = new NavigationHash(stateFromGitHub);
	}
	navigationHash.navigateToPerspective(ETeamscalePerspective.REPOSITORIES, ERepositoryPerspectiveView.SETUP.anchor);
}

/**
 * Extracts a URL query parameter that is e.g. set by GitHub when redirecting to Teamscale. In contrast to our regular
 * NavigationHash the parameter is not specified after the # symbol but before it.
 */
function getUrlParameter(name: string): string | null {
	return new URLSearchParams(window.location.search).get(name);
}
