import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';

/** Warns the user about insufficient RAM. */
export function useMemoryWarning(): JSX.Element | null {
	const teamscaleInfo = useTeamscaleInfo();
	if (teamscaleInfo.hasSufficientRam) {
		return null;
	}
	return (
		<GlobalWarning
			message="Insufficient Memory"
			severity="warning"
			tooltip={
				teamscaleInfo.availableMemory +
				' RAM for the JVM is insufficient for ' +
				teamscaleInfo.numberOfWorkers +
				' Teamscale workers (can lead to OutOfMemory). The minimum setting should be 2 GB per worker.'
			}
		/>
	);
}
