import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the API perspective */
export const EApiPerspectiveView = {
	/** The swagger API documentation. */
	API: {
		name: 'API',
		anchor: '',
		requiresProject: false,
		view: () => import('./SwaggerView')
	} as ViewDescriptor
};
