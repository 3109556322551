import { Icon, Menu } from 'semantic-ui-react';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import { LeftSidebarEntry } from 'ts/base/perspective/sidebar/left/LeftSidebarEntry';
import { TeamscaleLogo } from 'ts/base/perspective/sidebar/left/TeamscaleLogo';
import { useSidebarToggle } from 'ts/base/perspective/sidebar/SidebarToggleHook';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { PERSPECTIVES } from 'ts/Perspectives';
import './LeftSidebar.less';

/** Teamscale's left sidebar menu. */
export function LeftSidebar(): JSX.Element {
	const userInfo = useUserInfo();
	const [iconOnlySidebar, toggleManually] = useSidebarToggle();

	const visiblePerspectiveDescriptors = PERSPECTIVES.filter(
		perspectiveDescriptor =>
			perspectiveDescriptor.perspective.visible &&
			userInfo.allowedPerspectivePageNames.includes(perspectiveDescriptor.perspective.page)
	).sort(ArrayUtils.comparatorByKey(perspectiveDescriptor => perspectiveDescriptor.perspective.ordinal));

	return (
		<nav className="main-sidebar-container">
			<Menu borderless vertical inverted id="main-sidebar" className={iconOnlySidebar ? 'very thin' : undefined}>
				<TeamscaleLogo iconOnlySidebar={iconOnlySidebar} />
				{visiblePerspectiveDescriptors.map(perspectiveDescriptor => (
					<LeftSidebarEntry
						key={perspectiveDescriptor.perspective.simpleName}
						perspective={perspectiveDescriptor.perspective}
						perspectiveDescriptor={perspectiveDescriptor}
						iconOnlySidebar={iconOnlySidebar}
					/>
				))}
			</Menu>
			<div
				id="sidebar-toggle-menu"
				onClick={toggleManually}
				className={'ui menu inverted sidebar-toggle-menu ' + (iconOnlySidebar ? 'minimized' : '')}
				style={{ height: '38px' }}
			>
				<Menu.Item link id="toggle-sidebar-button">
					<Icon name={iconOnlySidebar ? 'angle double right' : 'angle double left'} />
					<span className="item__text">Collapse</span>
				</Menu.Item>
			</div>
		</nav>
	);
}
