import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';

/** Informs the user that shadow mod is enabled. */
export function useShadowModeWarning(): JSX.Element | null {
	const teamscaleInfo = useTeamscaleInfo();
	if (!teamscaleInfo.shadowMode) {
		return null;
	}
	return (
		<GlobalWarning
			message={
				<>
					Shadow Mode (
					<TeamscaleLink
						to={LinkTemplate.uniformLink({
							viewName: 'options',
							perspective: 'admin'
						})}
					>
						change
					</TeamscaleLink>
					)
				</>
			}
			severity="warning"
			tooltip="Shadow mode disables all external notifications, including emails, pull request annotations, and SAP downloads."
		/>
	);
}
