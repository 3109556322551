import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { wrapLicenseIfExists } from 'ts/data/ExtendedLicense';

/** Warns the user when there is no valid license or the license is expired/about to expire. */
export function LicenseWarning(): JSX.Element | null {
	const teamscaleInfo = useTeamscaleInfo();
	const license = wrapLicenseIfExists(teamscaleInfo.licenseInfo.license);
	if (!license) {
		return <GlobalWarning message="No valid license" severity="error" id="license-warning" />;
	} else if (license.expired) {
		return <GlobalWarning message={`License expired ${license.validTo}`} severity="error" id="license-warning" />;
	} else if (license.expiresSoon) {
		return <GlobalWarning message={`License expires ${license.validTo}`} severity="warning" id="license-warning" />;
	} else {
		return null;
	}
}
