import { ServiceCallError } from 'api/ServiceCallError';
import { useEffect } from 'react';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { NavigationHash } from 'ts/commons/NavigationHash';

/** Props for ErrorReportingComponent. */
type ErrorReportingComponentProps = { children: JSX.Element | JSX.Element[] };

/** Component that registers a global error handler and reports all errors or uncaught exceptions to Teamscale. */
export function ErrorReportingComponent({ children }: ErrorReportingComponentProps): JSX.Element {
	const teamscaleServiceClient = useTeamscaleServiceClient();
	const report = (errorMessage: string, project: string | null): void => {
		teamscaleServiceClient
			.reportJavaScriptError(errorMessage, project)
			.catch((serviceError: ServiceCallError): void =>
				console.error('Error reporting error to Teamscale: ' + serviceError.message)
			);
	};
	const getProject = () => {
		return NavigationHash.getProject() || null;
	};
	useEffect(() => {
		window.onerror = (
			msg: Event | string,
			url: string | undefined,
			line: number | undefined,
			column: number | undefined,
			error: Error | undefined
		): void => {
			if (error instanceof ServiceCallError) {
				return;
			}
			let errorMessage = error?.message ?? '';
			if (error?.stack) {
				errorMessage += '\n' + error.stack;
			}
			report(errorMessage, getProject());
		};

		// Onunhandledrejection is not safe to use on all browsers (e.g. not available on Firefox)
		window.onunhandledrejection = (rejection: PromiseRejectionEvent): void => {
			const errorMessage = JSON.stringify({ message: rejection.reason.message, stack: rejection.reason.stack });
			report(errorMessage, getProject());
		};
		return () => {
			window.onerror = null;
			window.onunhandledrejection = null;
		};
	});

	return <>{children}</>;
}
