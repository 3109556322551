import { useMutation } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import { useState } from 'react';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { Button, Form, Message, Modal, Search } from 'semantic-ui-react';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useKeyboardShortcut } from 'ts/base/hooks/UseKeyboardShortcut';
import { useProjectIdOrEmpty } from 'ts/base/hooks/UseProject';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { MODAL_CLOSE_ICON_STYLE } from 'ts/commons/dialog/SaveCancelModal';
import { FormProjectSelector } from 'ts/commons/forms/FormProjectSelector';
import { NavigationUtils } from 'ts/commons/NavigationUtils';

/** Dialog for navigating directly to a specific issue number. */
export function NavigateToIssueDialog() {
	const [open, setOpen] = useState(false);
	const openDialog = () => setOpen(true);
	const closeDialog = () => setOpen(false);
	useKeyboardShortcut(KeyboardShortcutRegistry.OPEN_ISSUE_PERSPECTIVE_SHORTCUT, 'Open issue dialog', openDialog);
	return (
		<Modal
			closeOnDimmerClick={false}
			open={open}
			onClose={closeDialog}
			onOpen={openDialog}
			closeIcon={MODAL_CLOSE_ICON_STYLE}
			style={{ width: '400px' }}
			role="dialog"
		>
			<NavigateToIssueDialogContent onClose={closeDialog} />
		</Modal>
	);
}

type IssueDialogValues = {
	issue: string;
	project: string;
};

function NavigateToIssueDialogContent({ onClose }: { onClose: () => void }) {
	const client = useTeamscaleServiceClient();
	const formContext = useForm<IssueDialogValues>({ defaultValues: { issue: '', project: useProjectIdOrEmpty() } });
	const { mutate, error } = useMutation(
		(values: IssueDialogValues) => client.getIssue(values.project, values.issue),
		{
			onSuccess: (resolvedIssue, values) => {
				onClose();
				NavigationUtils.updateLocation(
					LinkTemplate.issue({ project: values.project, id: resolvedIssue.issue.id.internalId })
				);
			}
		}
	);
	const errors = [...Object.values(formContext.formState.errors), ...(error ? [error as Error] : [])];

	return (
		<>
			<Modal.Header>Navigate to Issue</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<FormProvider {...formContext}>
						<Form
							error={errors.length > 0}
							onSubmit={formContext.handleSubmit(values => mutate(values))}
							id="navigate-to-issue-form"
						>
							<Message error content={errors.map(error => error.message).join('\n')} />
							<IssueSearchInput />
							<Form.Field inline error={formContext.formState.errors.project}>
								<label>Project</label>
								<FormProjectSelector<IssueDialogValues>
									name="project"
									rules={{ required: 'Project must not be empty.' }}
								/>
							</Form.Field>
							<input type="submit" hidden />
						</Form>
					</FormProvider>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button content="Cancel" onClick={onClose} />
				<Button
					positive
					content="Go to issue"
					labelPosition="right"
					icon="checkmark"
					type="submit"
					form="navigate-to-issue-form"
				/>
			</Modal.Actions>
		</>
	);
}

/** Provides an issue search field with issue ID autocompletion and integrates it with react-hook-form. */
function IssueSearchInput(): JSX.Element {
	const activeProjectId = useFormContext<IssueDialogValues>().getValues('project');
	const controller = useController<IssueDialogValues>({
		name: 'issue',
		rules: { required: 'Issue ID must not be empty.' }
	});
	const projectSelected = activeProjectId !== '';
	const queryResult = QUERY.autocompleteIssueId(activeProjectId, { search: controller.field.value }).useQuery({
		enabled: projectSelected
	});

	return (
		<Form.Field error={Boolean(controller.fieldState.error)}>
			<label>Issue ID</label>
			<Search
				autoFocus
				showNoResults={projectSelected}
				loading={queryResult.isFetching}
				id="navigate-to-issue-dialog-input"
				icon="search"
				autoComplete="off"
				placeholder="Issue ID"
				input={{ fluid: true }}
				className="prompt"
				onResultSelect={(result, data) => controller.field.onChange(data.result.title)}
				onSearchChange={(e, data) => controller.field.onChange(data.value!)}
				results={queryResult.data?.map(issue => ({ title: issue }))}
				value={controller.field.value}
			/>
		</Form.Field>
	);
}
