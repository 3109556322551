import type { License } from 'typedefs/License';

/** Extended version of License. */
export type ExtendedLicense = License & {
	numUsersFormatted: string;
};

function wrapLicense(license: License): ExtendedLicense {
	let numUsersFormatted = String(license.numUsers);
	if (license.numUsers > 99999999) {
		numUsersFormatted = 'Unlimited';
	}
	return Object.assign(license, {
		numUsersFormatted
	});
}

/** Extends the license with human readable fields. */
export function wrapLicenseIfExists(license: License | null | undefined): ExtendedLicense | null {
	if (license !== undefined && license !== null) {
		return wrapLicense(license);
	}
	return null;
}
