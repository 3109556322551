import clsx from 'clsx';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';

/** Props for SubViewMenu. */
type SubViewMenuProps = {
	views: ViewDescriptor[] | null;
};

/** Creates the menu for switching views within a perspective from an expanded sidebar. */
export function SubViewMenu({ views }: SubViewMenuProps): JSX.Element | null {
	const hash = useNavigationHash();
	const currentView = hash.getViewName();
	if (views === null || views.length === 0) {
		return null;
	}

	const activeSubview = PerspectiveViewDescriptorBase.findViewDescriptor(
		views,
		currentView ?? '',
		hash.getAction() ?? undefined
	);
	return (
		<div id="sub-menu-container" className="ui secondary left pointing vertical menu" style={{ width: '100%' }}>
			{views.map(view => (
				<div key={view.anchor} className="item-wrapper">
					<TeamscaleLink
						data-name={view.name}
						className={clsx('item', 'view-switcher-' + view.anchor, {
							active: view.anchor === activeSubview?.anchor
						})}
						to={getHashForView(view, hash)}
					>
						{view.name}
					</TeamscaleLink>
				</div>
			))}
		</div>
	);
}

/** Returns the navigation hash part of a link to that view. */
function getHashForView(viewDescriptor: ViewDescriptor, currentHash: NavigationHash): string {
	return `#${viewDescriptor.anchor}/${currentHash.getProject()}${
		viewDescriptor.keepPathAndArgumentsOfCurrentViewForSubviews
			? '/' + currentHash.getProjectAndPath().getPath() + currentHash.getArgumentsString()
			: ''
	}`;
}
