import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { ESearchPerspectiveView } from 'ts/perspectives/search/ESearchPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the search perspective. */
export class SearchPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.SEARCH, ESearchPerspectiveView);
	}

	public override getProject(
		perspectiveContext: ExtendedPerspectiveContext,
		hash: NavigationHash,
		viewDescriptor: ViewDescriptor
	): string | null {
		return hash.getProject();
	}
}
