import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { UserPerspectiveDescriptor } from 'ts/perspectives/user/UserPerspectiveDescriptor';

/**
 * Returns the sub-views of a perspective that the user has access to. This is async, because some views are
 * shown/hidden depending on server calls.
 */
export function useAccessibleViews(
	perspectiveDescriptor: UserPerspectiveDescriptor,
	options?: Omit<UseQueryOptions<ViewDescriptor[]>, 'queryKey' | 'queryFn' | 'initialData'>
): UseQueryResult<ViewDescriptor[]> {
	const hash = useNavigationHash();
	const client = useTeamscaleServiceClient();
	const context = usePerspectiveContext();
	return useQuery(
		['accessible-views', context, hash, perspectiveDescriptor],
		() => perspectiveDescriptor.getAccessibleViewDescriptors(context, client, hash),
		options
	);
}

/**
 * Returns the sub-views of a perspective that the user has access to. This is async, because some views are
 * shown/hidden depending on server calls.
 */
export function useAccessibleSidebarViews(
	perspectiveDescriptor: UserPerspectiveDescriptor,
	options?: Omit<UseQueryOptions<ViewDescriptor[]>, 'queryKey' | 'queryFn'>
) {
	const accessibleViewDescriptors = useAccessibleViews(perspectiveDescriptor, options).data ?? [];
	return accessibleViewDescriptors.filter(viewDescriptor => viewDescriptor.visibleInSidebar);
}
