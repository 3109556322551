import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';

/** Warns the user when the default admin account and password is enabled. */
export function useDefaultAdminEnabledWarning(): JSX.Element | null {
	const teamscaleInfo = useTeamscaleInfo();
	if (!teamscaleInfo.warnForDefaultAdminEnabled) {
		return null;
	}
	return (
		<GlobalWarning
			message="Default admin user enabled."
			tooltip={
				'You can either change the admin password or disable the user (under the Admin->Users tab) ' +
				'or disable this warning ' +
				'(using the system property -Dcom.teamscale.feature-toggle.no-warn-on-unsafe-admin-user=true)'
			}
			severity="warning"
		/>
	);
}
