import type { UseMutateFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { ToastNotification } from 'ts/commons/ToastNotification';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import styles from './DefaultBackupSettingsWarning.module.less';

/** Informs the user that the default backup settings are active and have not yet been actively acknowledged by an admin. */
export function useDefaultBackupSettingsWarning(): JSX.Element | null {
	const client = useTeamscaleServiceClient();
	const [userHasAcknowledgedDefaultSettings, setUserHasAcknowledgedDefaultSettings] = useState(false);
	const { mutate: handleAcknowledgeDefaultSettings } = useMutation(
		() => client.setServerOption('acknowledge-backup-settings', { acknowledged: true }, false),
		{
			onSuccess() {
				setUserHasAcknowledgedDefaultSettings(true);
			},
			onError(e) {
				ToastNotification.error('We are sorry. Accepting the automatic backup settings failed.' + e);
			}
		}
	);

	const showWarning = useTeamscaleInfo().showDefaultBackupSettingsWarning && !userHasAcknowledgedDefaultSettings;
	if (!showWarning) {
		return null;
	}
	return <DefaultBackupSettingsWarning handleAcknowledgeDefaultSettings={handleAcknowledgeDefaultSettings} />;
}

type DefaultBackupSettingsWarningProps = {
	handleAcknowledgeDefaultSettings: UseMutateFunction;
};

function DefaultBackupSettingsWarning({
	handleAcknowledgeDefaultSettings
}: DefaultBackupSettingsWarningProps): JSX.Element {
	const hash = useNavigationHash();
	const linkToSettings: string = LinkTemplate.uniformLink({
		viewName: 'options',
		perspective: 'admin',
		parameters: { action: 'Automatic Backup' }
	});
	return (
		<GlobalWarning
			message={
				<>
					Automatic Backups Are Enabled With Default <br /> Settings (
					<button
						className="teamscale-link-button"
						onClick={() =>
							handleAcknowledgeDefaultSettings(undefined, {
								onSuccess() {
									hash.setAction('Automatic Backup');
									hash.navigateToPerspective(ETeamscalePerspective.ADMIN, 'options');
								}
							})
						}
						title="Review the default settings for periodic automatic backups."
					>
						Review Settings
					</button>
					<span className={styles.separator}>or</span>
					<button
						className="teamscale-link-button"
						onClick={() =>
							handleAcknowledgeDefaultSettings(undefined, {
								onSuccess() {
									ToastNotification.info(
										`The default settings have been accepted. You can revisit the backup settings anytime in the <a href="${linkToSettings}">Admin perspective</a>.`
									);
								}
							})
						}
						title="Accept to keep the default settings for periodic automatic backups."
					>
						Accept
					</button>
					)
				</>
			}
			severity="warning"
			tooltip="Periodic automatic backups are enabled with default settings. Please review these settings to verify that they are in line with your preferences. Alternatively, you can accept to keep the default settings."
			multiline
		/>
	);
}
