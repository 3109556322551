import { LicenseUtils } from 'ts/base/utils/LicenseUtils';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

export const EMetricsPerspectiveView = {
	FILES: {
		name: 'Files',
		anchor: 'code',
		requiresProject: true,
		visibleInSidebar: true,
		viewFactory: () => import('./MetricsDelegateView'),
		timeTravel: {
			contentName: ETeamscalePerspective.METRICS.displayName
		}
	} as ViewDescriptor,
	ARCHITECTURE_COMPONENTS: {
		name: 'Architectures',
		anchor: 'architecture',
		requiresProject: true,
		visibleInSidebar: true,
		viewFactory: () => import('./MetricsDelegateView'),
		timeTravel: {
			contentName: ETeamscalePerspective.METRICS.displayName
		}
	} as ViewDescriptor,
	ISSUES: {
		name: 'Issues',
		anchor: 'issues',
		requiresProject: true,
		visibleInSidebar: true,
		viewFactory: () => import('./MetricsDelegateView'),
		timeTravel: {
			contentName: ETeamscalePerspective.METRICS.displayName
		}
	} as ViewDescriptor,
	SPEC_ITEMS: {
		name: 'Spec Items',
		anchor: 'spec-items',
		requiresProject: true,
		visibleInSidebar: true,
		viewFactory: () => import('./MetricsDelegateView'),
		canBeAccessed: async context => LicenseUtils.hasRequirementsManagementToolsEnabled(context),
		timeTravel: {
			contentName: ETeamscalePerspective.METRICS.displayName
		}
	} as ViewDescriptor,
	TESTS: {
		name: 'Tests',
		anchor: 'tests',
		requiresProject: true,
		visibleInSidebar: true,
		viewFactory: () => import('./MetricsDelegateView'),
		timeTravel: {
			contentName: ETeamscalePerspective.METRICS.displayName
		}
	} as ViewDescriptor,
	NON_CODE: {
		name: 'Non-Code',
		anchor: 'noncode',
		requiresProject: true,
		visibleInSidebar: true,
		viewFactory: () => import('./MetricsDelegateView'),
		timeTravel: {
			contentName: ETeamscalePerspective.METRICS.displayName
		}
	} as ViewDescriptor
} as const;
