import { useEffect } from 'react';
import { Menu } from 'semantic-ui-react';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useActivePerspective } from 'ts/base/hooks/UseActivePerspective';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { ProjectResolver } from 'ts/base/ProjectResolver';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { ProjectSelector } from 'ts/base/scaffolding/ProjectSelector';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { TimetravelUtils } from 'ts/commons/TimetravelUtils';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for GlobalProjectSelector. */
type GlobalProjectSelectorProps = {
	projectIsFixed: boolean;
	showAllProjects: boolean;
};

/** The global project selector that is shown in the perspective settings bar. */
export function GlobalProjectSelector({ projectIsFixed, showAllProjects }: GlobalProjectSelectorProps): JSX.Element {
	const projectInfo = useProjectIfExists();
	const client = useTeamscaleServiceClient();
	const primaryId = projectInfo?.primaryId;
	useEffect(() => {
		if (primaryId != null) {
			void client.registerProjectSelection(primaryId);
		}
	}, [client, primaryId]);

	const hashGenerator = useProjectLinkToCurrentPerspective();

	/** Callback for updating the current project value in the browser local storage. */
	function onProjectSelectionChange(projectId: string): void {
		void client.registerProjectSelection(projectId);
		ProjectResolver.setCurrentProject(projectId);
		TimetravelUtils.setCurrentCommit(null);
		const hash = hashGenerator(projectId);
		hash.navigate(true);
	}

	return (
		<Menu.Item fitted className="no-right-margin" title={'Selected project: ' + primaryId}>
			<Menu.Item className="side-fitting project-selector-container">
				<ProjectSelector
					asItem={ProjectLink}
					activeProjectId={primaryId}
					selectorId="project-select"
					showAllProjectsItem={showAllProjects}
					projectIsFixed={projectIsFixed}
					onChange={onProjectSelectionChange}
				/>
			</Menu.Item>
		</Menu.Item>
	);
}

/** Props for ProjectLink. */
type ProjectLinkProps = { 'data-value': string } & Record<string, unknown>;

/** Links to the given project while keeping the current perspective and view. */
function ProjectLink({ 'data-value': projectId, ...props }: ProjectLinkProps) {
	const hashGenerator = useProjectLinkToCurrentPerspective();
	return <TeamscaleLink to={hashGenerator(projectId).toString()} {...props} />;
}

/** Hook that returns a function, which generates a navigation hash to a given project. */
function useProjectLinkToCurrentPerspective(): (projectId: string) => NavigationHash {
	const hash = useNavigationHash();
	const activePerspective = useActivePerspective();
	if (activePerspective !== ETeamscalePerspective.SEARCH) {
		hash.refreshArguments('');
	}
	return (projectId: string) => {
		const path = ProjectAndUniformPath.of(projectId, null);
		hash.setProjectAndPath(path);
		return hash;
	};
}
